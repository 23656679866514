import { FC } from 'react'
import NextHead from 'next/head'
import { useRouter } from 'next/router'
import path from 'path'

const Head: FC = () => {
  const { locale, asPath: currentPath, locales } = useRouter()

  const buildSiteUrl: Function = (l: string): string => path.normalize(['https://ennsens.com', l === 'en-US' ? null : l, currentPath].filter(Boolean).join('/'))
  const filteredLocales: string[] = locales?.filter((l: string) => l !== locale) || []

  return (
    <>
      <NextHead>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="manifest" href="/site.webmanifest" key="site-manifest" />
        <link rel="alternate" href={buildSiteUrl('en-US')} hrefLang="x-default" />
        <link rel="alternate" href={buildSiteUrl('en-US')} hrefLang="en-US" />
        <link rel="alternate" href={buildSiteUrl('cs-CZ')} hrefLang="cs-CZ" />
        <meta name="twitter:url" content={buildSiteUrl(locale)} />
        <meta property="og:site_name" content="Ennsens" />
        <link rel="author" href="info@hirt.cz" />
        <meta property="og:locale" content={locale} />
        {filteredLocales?.map((l: string) => <meta key={l} property='og:locale:alternate' content={l} /> )}
        <meta property="og:url" content={buildSiteUrl(locale)} />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000" />
        <meta name="msapplication-TileColor" content="#000000" />
        <meta name="theme-color" content="#ffffff" />
      </NextHead>
    </>
  )
}

export default Head
