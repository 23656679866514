import '@assets/main.css'
import '@assets/chrome-bug.css'
import 'keen-slider/keen-slider.min.css'
import 'react-toastify/dist/ReactToastify.css';

import { FC, useEffect } from 'react'
import type { AppProps } from 'next/app'
import { appWithTranslation } from 'next-i18next';
import { useRouter } from 'next/router'
import { ToastContainer } from 'react-toastify';

import { Head } from '@components/common'
import mixpanel from '@lib/mixpanel'

const Noop: FC = ({ children }) => <>{children}</>

function MyApp({ Component, pageProps }: AppProps) {
  const Router = useRouter()
  const Layout = (Component as any).Layout || Noop

  useEffect(() => {
    const trackPageView = (): void => {
      if (process.env.NODE_ENV === 'production') {
        mixpanel.track("Page View")

        return
      }

      console.info('Track page view')
    }

    document.body.classList?.remove('loading')
    Router.events.on('routeChangeComplete', trackPageView)

    trackPageView()

    return () => {
      Router.events.off('routeChangeComplete', trackPageView)
    }
  }, [Router.events])

  return (
    <>
      <Head />
      <Layout pageProps={pageProps}>
        <Component {...pageProps} />
        <ToastContainer pauseOnFocusLoss={false} />
      </Layout>
    </>
  )
}

export default appWithTranslation(MyApp);